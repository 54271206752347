<!-- 转账记录 -->
<template>
  <div class="transferRecord">
    <header class="headerFilter" @click="showFilter">
      <van-icon name="filter-o" />
      <span class="title">筛选</span>
    </header>
    <van-popup v-model:show="show" position="top" :style="{ height: '30%' }">
      <p style="text-align: center">筛选过滤</p>
      <!-- <p class="filterItem"> -->

      <van-cell title="选择日期区间:" :value="filterData.start_time + '至' + filterData.end_time" @click="timeShow = true" />

      <!-- </p> -->
      <p class="filterItem"><span>订单状态:</span><select v-model="filterData.co_status">
          <option v-for="item in statusList" :key="item.value" :value="item.value">{{ item.label }}</option>
        </select>
      </p>
      <p style="text-align: center">
        <van-button type="primary" @click="filterSearch">确定</van-button>
      </p>
    </van-popup>
    <van-calendar v-model:show="timeShow" type="range" :min-date="minDate" :max-date="maxDate" @confirm="onConfirm" />
    <van-list v-model:loading="state.loading" :finished="state.finished" finished-text="没有更多了" @load="onLoad">
      <div class="oneTransferRecord" v-for="(item, index) in recordList" :key="index">
        <p>
          <span class="co_noText">订单号:{{ item.co_no }}</span>
        </p>
        <div class="flex4">
          <p class="flex3 co_priceText">
            <span>提现金额 :
              <span class="colorRed">{{ item.co_price }}</span></span>

            <span v-if="item.bank_name">提现方式 :
              {{
                item.co_type == 1
                ? "支付宝"
                : item.co_type == 2
                  ? "微信"
                  : item.co_type == 3
                    ? "银行卡"
                    : ""
              }}</span>
            <span v-if="item.bank_name">银行 : {{ item.bank_name }}</span>
            <span v-if="item.wx_name">微信昵称 : {{ item.wx_name }}</span>
            <span>收款账号 : {{ item.u_ali_account }}</span>

            <span>收款人姓名 : {{ item.u_ali_realname }}</span>
            <span>提交时间 : {{ item.co_addtime | ts2Date() }}</span>
          </p>
          <p>
            <span class="state" :style="{
              color:
                item.co_status == 0
                  ? '#0882ea'
                  : item.co_status == 1
                    ? '#04d932'
                    : '#ff3e13',
            }">
              {{
                item.co_status == 0
                ? "已提交"
                : item.co_status == 1
                  ? "已通过"
                  : item.co_status == 2
                    ? "已驳回"
                    : "失败"
              }}</span>
          </p>
        </div>
        <div class="flex2" v-if="item.co_status != 0">
          <span v-if="item.co_status == 2 || item.co_status == 3" style="color:#f83130 ;font-size: 10px">备注:</span>
          <span class="co_noText">审核时间:{{ item.co_verifytime | ts2Date() }}</span>
        </div>
        <p v-if="item.co_status == 2 || item.co_status == 3">
          <span class="co_verify_msg">{{ item.co_remark }}</span>
        </p>
      </div>
    </van-list>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recordList: [],
      state: {
        loading: false,
        finished: false,
      },
      statusList: [
        {
          label: "审核中",
          value: 0,
        },
        {
          label: "通过",
          value: 1,
        },
        {
          label: "驳回",
          value: 2,
        },
        {
          label: "所有状态",
          value: "",
        },
      ],
      reqParams: {
        page: 1,
        limit: 10,
      },
      minDate: new Date(2021, 12, 1),
      maxDate: new Date(),
      isPullRefresh: true,
      filterData: {
        start_time: "",
        end_time: "",
        co_status: "",
      },
      show: false,
      timeShow: false,
    };
  },

  components: {},

  mounted() { },

  methods: {
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    showFilter() {
      this.show = true;
    },

    filterSearch() {
      this.show = false;
      this.recordList = [];
      this.reqParams.page = 1;
      this.getRecordList();
    },
    onConfirm(values) {
      const [start, end] = values;
      // console.log(values);
      this.filterData.start_time = this.formatDate(start);
      this.filterData.end_time = this.formatDate(end);
      this.timeShow = false;
    },
    async onLoad() {
      console.log("上拉加载", this.isPullRefresh);

      if (this.isPullRefresh) {
        this.isPullRefresh = false;
        this.getRecordList();
      }
    },
    async getRecordList() {
      console.log("请求", this.filterData);
      let filterObj = { ...this.reqParams, ...this.filterData };
      if (this.filterData.start_time) {
        filterObj.start_time = this.filterData.start_time + " 00:00:01";
        filterObj.end_time = this.filterData.end_time + " 23:59:59";
      }
      this.$axios
        .get("/api/account/getCashOrderList", {
          params: filterObj,
        })
        .then((res) => {
          console.log(res);
          if (res.code == "000") {
            this.reqParams.page++;
            this.recordList = this.recordList.concat(res.data.rows);
            // 数据全部加载完成
            if (this.recordList.length >= res.data.total) {
              this.state.finished = true;
            }
          }
          this.isPullRefresh = true;
          this.state.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isPullRefresh = true;
          this.state.loading = false;
        });
    },
  },
  filters: {
    ts2Date: (timestamp) => {
      if (timestamp == 0) {
        return "";
      }
      let date = new Date(timestamp * 1000), //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        Y = date.getFullYear() + "-",
        M =
          (date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1) + "-",
        D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ",
        h =
          (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) +
          ":",
        m =
          (date.getMinutes() < 10
            ? "0" + date.getMinutes()
            : date.getMinutes()) + ":",
        s =
          date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
};
</script>
<style lang="scss">
.transferRecord {
  .headerFilter {
    height: 40px;
    line-height: 40px;
    position: fixed;
    top: 0px;
    z-index: 99999;
    color: #fff;
    right: 13px;

    .title {
      font-size: 15px;
    }
  }

  .headerTitle {
    font-size: 20px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid #eee;
  }

  .oneTransferRecord {
    margin: 5px 10px 10px 10px;
    margin-top: 3px;
    border-bottom: 1px solid rgb(31, 31, 31);
    padding-bottom: 5px;

    .co_noText {
      color: gray;
      font-size: 10px;
    }

    .co_priceText {
      span {
        font-size: 15px;
        color: rgb(19, 4, 4);
      }

      .colorRed {
        color: #f83130;
      }
    }

    p {
      padding: 0px;
      margin: 0px;
    }

    .co_verify_msg {
      display: inline-block;
      white-space: pre-wrap;
      word-break: normal;
      display: block;
      word-wrap: break-word;
      overflow: hidden;
      color: #f83130;
      font-size: 10px;
    }

    .state {
      font-size: 15px;
      font-weight: 500;
    }
  }

  .flex4 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex2 {
    display: flex;
    justify-content: space-between;
  }

  .flex3 {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .filterItem {
    padding: 5px 15px 5px 15px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
